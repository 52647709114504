import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import styles from "./styles.css?url";
import { LinksFunction, LoaderFunctionArgs, json } from "@remix-run/node";
import { LoadingBar } from "./components/layout/LoadingBar";
import { commitSession, getSession } from "./sessions/sessions";
import { Toaster } from "~/components/ui/sonner";
import { Button } from "./components/ui/button";

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: styles }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const toast = session.get("toast");

  // Don't commit the session if there's no toast,
  // otherwise remix will continually revalidate all loaders
  const headers =
    toast ? { "Set-Cookie": await commitSession(session) } : undefined;

  return json({ toast }, { headers });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" sizes="any" type="image/png" />
        <Meta />
        <Links />
        <script
          defer
          data-domain="covid-chemistry.org"
          src="/js/script.js"
        ></script>
      </head>
      <body>
        <LoadingBar />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const navigate = useNavigate();

  const errorTitle =
    isRouteErrorResponse(error) ?
      `${error.status} ${error.statusText}`
    : "Error";
  const errorMessage =
    isRouteErrorResponse(error) ? error.data
    : error instanceof Error ? error.stack
    : "Unknown error";

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <div
          // This will be the nav
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <img src="/logo.png" alt="" className="size-8 mr-4" />
            Covid Chemistry
          </div>
        </div>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-brand-300 to-brand-400 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              {errorTitle}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {errorMessage}
            </p>
            <Button
              variant="outline"
              className="mt-6"
              onClick={() => navigate(location)}
            >
              Reload
            </Button>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default function App() {
  const { toast } = useLoaderData<typeof loader>();

  return (
    <>
      <Outlet />
      <Toaster serverToast={toast} />
    </>
  );
}
