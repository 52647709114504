import { useFetchers, useNavigation } from "@remix-run/react";
import NProgress from "nprogress";
import { useEffect, useMemo } from "react";

NProgress.configure({
  // showSpinner: false,
  trickleSpeed: 400,
});

export const LoadingBar = () => {
  const navigation = useNavigation();
  const fetchers = useFetchers();

  const state = useMemo<"idle" | "loading">(() => {
    const states = [
      navigation.state,
      ...fetchers.map((fetcher) => fetcher.state),
    ];
    if (states.every((state) => state === "idle")) return "idle";
    return "loading";
  }, [fetchers, navigation.state]);

  useEffect(() => {
    if (state === "idle") NProgress.done();
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    if (state === "loading") {
      const timeout = setTimeout(() => {
        NProgress.start();
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [state]);

  return null;
};
